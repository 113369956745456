<template>
  <div class="xxjj">
    <div class="page-herader">
      <span>实验室</span>
    </div>
    <div class="lmy-center">
      <div class="teachers_name">
        <div class="teachers_lb">
          <ul>
            <li>
              <a href="http://brd.bisu.edu.cn/" target="_blank"
                >国家“一带一路”数据分析与决策支持北京市重点实验室</a
              >
            </li>
            <div style="display: none">
              <li><a>国家“一带一路”数据分析与决策支持北京市重点实验室</a></li>
            </div>
            <li>
              <a href="http://lscl.bisu.edu.cn/" target="_blank"
                >人工智能与语言认知实验室</a
              >
            </li>
            <div style="display: none">
              <li><a>人工智能与语言认知实验室</a></li>
            </div>
            <li><a>数字资源与知识服务实验室</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
      
      <script>
export default {
  data() {
    return {};
  },
};
</script>
      
    <style lang="scss" scoped>
.lmy-center {
  margin-top: 18px;
  margin-bottom: 36px;

  .teachers_name {
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
  }
  .teachers_lb {
    font-size: 20px;
    padding: 10px 0;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
  }
  ul li {
    width: 420px;
    margin-left: 20px;
    margin-right: 20px;
    border-bottom: solid 1px #e3e3e3;
    padding: 20px;
    a {
      color: #333;
      font-size: 20px;
      line-height: 20px;
    }
    span{
      cursor: pointer;
    }
  }
}
</style>
      