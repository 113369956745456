<template>
  <div class="xxjj">
    <div class="page-herader">
      <span>科研项目</span>
    </div>
    <div class="lmy-center">
      <div class="teachers_name">
        <div class="teachers_lb">
          <ul v-if="!$route.query.detail">
            <li v-for="(item, index) in list" :key="item.id">
              <a :href="`/#/research?id=5&detail=${index}`" target="_blank">{{
                item.title
              }}</a>
            </li>
          </ul>
          <div v-if="$route.query.detail" v-html="list[$route.query.detail].content"></div>
        </div>
      </div>
    </div>
  </div>
</template>
            
            <script>
export default {
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.getlist();
  },
  methods: {
    async getlist() {
      const res = await this.API.basic.getlist({
        columnIds: String("6358a958e4b04c943379d5fc"),
        pageNumber: 0,
        pageSize: 100,
      });
      this.list = res.data?.content;
    },
  },
};
</script>
            
          <style lang="scss" scoped>
.lmy-center {
  margin-top: 18px;
  margin-bottom: 36px;

  .teachers_name {
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
  }
  .teachers_lb {
    font-size: 20px;
    padding: 10px 0;
    width: 100%;
    ::v-deep table{
      margin: auto;
    }
  }
  ul {
    display: flex;
    flex-wrap: wrap;
  }
  ul li {
    width: 420px;
    margin-left: 20px;
    margin-right: 20px;
    border-bottom: solid 1px #e3e3e3;
    padding: 20px;
    a {
      color: #333;
      font-size: 20px;
      line-height: 20px;
    }
    span{
      cursor: pointer;
    }
  }
}
</style>
            