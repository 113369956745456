<template>
  <div class="columnList">
    <div class="conent">
      <!-- 栏目页大图 -->
      <div class="lbt">
        <img src="../../assets/img/banner.jpg" />
      </div>
      <div class="tjlm_bg">
        <div class="tjlm">
          <ul v-for="(item, index) in tablist" :key="index">
            <li :id="item.id" :class="cuIdx === index ? 'tjlm_on' : ''">
              <a v-if="item.erOriginLink" :href="item.erOriginLink" target="_blank">{{
                item.name
              }}</a>
              <span v-else @click="reload(item.components, index,item)" :title="item.id">{{
                item.name
              }}</span>
            </li>
          </ul>
        </div>
      </div>
      <component ref="currentTabComponent" :is="currentTabComponent" class="w1380 lmy"></component>
    </div>
  </div>
</template>
  
  <script>
import yjy from "./components/yjy.vue";
import sys from "./components/sys.vue";
import yjjd from "./components/yjjd.vue";
import yjzx from "./components/yjzx.vue";
import kyxm from "./components/kyxm.vue";
import kycg from "./components/kycg.vue";
export default {
  components: {
    yjy,
    sys,
    yjjd,
    yjzx,
    kyxm,
    kycg,
  },
  data() {
    return {
      cuIdx: 0,
      tablist: [
      ],
      currentTabComponent: "yjy",
    };
  },
  created() {
    this.cuIdx = this.$route.query.id ? Number(this.$route.query.id) : 0;
    // this.currentTabComponent = this.tablist[this.cuIdx].components;
    this.getTreeList();
  },
  methods: {
    async getTreeList() {
      const res = await this.API.basic.getColumn();
      if (res.data && res.data.length > 0) {
        let findColumn = res.data.findIndex(i => { return i.uuid == this.$columnIdFile.kxyj})
        findColumn != '-1' ? this.tablist = res.data[findColumn].children : this.tablist = []
        this.tablist.forEach( async i => {
          if (i.name === "学术期刊") {
            i.erOriginLink = "https://journal.bisu.edu.cn/";
            return;
          }
          if(i.uuid == this.$columnIdFile.kxyjxsqk) {
            const params = {
              pageNumber: 0,
              pageSize: 1,
              columnIds: i.uuid
            }
            const res = await this.API.basic.getHomeRotographList(params);
            i.erOriginLink = res.data.content[0].mapProperties.erOriginLink
          }else {
            i.erOriginLink = null
          }
          this.$forceUpdate()
        });
        this.$refs.currentTabComponent.content = this.tablist[this.cuIdx]
        // this.currentTabComponent = "common";
        // if (this.$route.query.id) {
        //   this.curPageTitle = this.tablist[this.cuIdx].columnName;
        //   this.curData = this.tablist[this.cuIdx].childList;
        // }
      } else {
        // this.cuIdx = this.$route.query.id ? Number(this.$route.query.id) : 0;
        // this.currentTabComponent = this.tablist[this.cuIdx].components;
      }
    },
    reload(component, index,val) {
      this.cuIdx = index;
      // this.currentTabComponent = component;
      this.$refs.currentTabComponent.content = val
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.columnList {
  padding-bottom: 50px;
  .conent .lbt {
    position: relative;
  }
  .conent .lbt img {
    width: 100%;
  }
}
</style>
  