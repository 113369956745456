<template>
  <div class="xxjj">
    <div class="page-herader">
      <span>研究基地</span>
    </div>
    <div class="lmy-center">
      <div class="teachers_name">
        <div class="teachers_lb">
          <ul>
            <li><a>文化和旅游研究基地</a></li>
            <li>
              <a href="http://bjtourism.bisu.edu.cn/" target="_blank"
                >北京旅游发展研究基地</a
              >
            </li>
            <div style="display: none">
              <li><a>北京旅游发展研究基地</a></li>
            </div>
            <li>
              <a href="http://bric.bisu.edu.cn/" target="_blank"
                >北京对外文化传播研究基地</a
              >
            </li>
            <div style="display: none">
              <li><a>北京对外文化传播研究基地</a></li>
            </div>
            <li>
              <a href="http://brict.bisu.edu.cn/" target="_blank"
                >首都对外文化贸易研究基地</a
              >
            </li>
            <div style="display: none">
              <li><a>首都对外文化贸易研究基地</a></li>
            </div>
            <li>
              <a href="http://wenyi.bisu.edu.cn/" target="_blank"
                >中国文艺评论研究基地</a
              >
            </li>
            <div style="display: none">
              <li><a>中国文艺评论研究基地</a></li>
            </div>
            <li>
              <a href="http://tcsc.bisu.edu.cn/" target="_blank"
                >中日韩合作研究中心</a
              >
            </li>
            <div style="display: none">
              <li><a>中日韩合作研究中心</a></li>
            </div>
            <li>
              <a href="http://smes.bisu.edu.cn/" target="_blank"
                >阿拉伯研究中心</a
              >
            </li>
            <div style="display: none">
              <li><a>阿拉伯研究中心</a></li>
            </div>
            <li><a>全球舆情与受众研究基地</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
        
        <script>
export default {
  data() {
    return {};
  },
};
</script>
        
      <style lang="scss" scoped>
.lmy-center {
  margin-top: 18px;
  margin-bottom: 36px;

  .teachers_name {
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
  }
  .teachers_lb {
    font-size: 20px;
    padding: 10px 0;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
  }
  ul li {
    width: 420px;
    margin-left: 20px;
    margin-right: 20px;
    border-bottom: solid 1px #e3e3e3;
    padding: 20px;
    a {
      color: #333;
      font-size: 20px;
      line-height: 20px;
    }
    span{
      cursor: pointer;
    }
  }
}
</style>
        