<template>
  <div class="xxjj">
    <div class="page-herader">
      <span>研究中心</span>
    </div>
    <div class="lmy-center">
      <div class="teachers_name">
        <div class="teachers_lb">
          <ul>
            <li>
              <a href="http://jkw.bisu.edu.cn/" target="_blank"
                >联合国教科文组织研究中心</a
              >
            </li>
            <div style="display: none">
              <li><a>联合国教科文组织研究中心</a></li>
            </div>
            <li>
              <a href="http://cee.bisu.edu.cn/" target="_blank"
                >中东欧研究中心</a
              >
            </li>
            <div style="display: none">
              <li><a>中东欧研究中心</a></li>
            </div>
            <li>
              <a href="http://bilu.bisu.edu.cn" target="_blank"
                >秘鲁文化研究中心</a
              >
            </li>
            <div style="display: none">
              <li><a>秘鲁文化研究中心</a></li>
            </div>
            <li>
              <a href="http://be.bisu.edu.cn/" target="_blank"
                >白俄罗斯研究中心</a
              >
            </li>
            <div style="display: none">
              <li><a>白俄罗斯研究中心</a></li>
            </div>
            <li>
              <a href="http://danmai.bisu.edu.cn/" target="_blank"
                >丹麦研究中心</a
              >
            </li>
            <div style="display: none">
              <li><a>丹麦研究中心</a></li>
            </div>
            <li>
              <a href="http://greece.bisu.edu.cn/" target="_blank"
                >希腊研究中心</a
              >
            </li>
            <div style="display: none">
              <li><a>希腊研究中心</a></li>
            </div>
            <li>
              <a href="http://poland.bisu.edu.cn/" target="_blank"
                >波兰研究中心</a
              >
            </li>
            <div style="display: none">
              <li><a>波兰研究中心</a></li>
            </div>
            <li><a>奥地利研究中心（校级）</a></li>
            <li><a>匈牙利研究中心（校级）</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
          
          <script>
export default {
  data() {
    return {};
  },
};
</script>
          
        <style lang="scss" scoped>
.lmy-center {
  margin-top: 18px;
  margin-bottom: 36px;

  .teachers_name {
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
  }
  .teachers_lb {
    font-size: 20px;
    padding: 10px 0;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
  }
  ul li {
    width: 420px;
    margin-left: 20px;
    margin-right: 20px;
    border-bottom: solid 1px #e3e3e3;
    padding: 20px;
    a {
      color: #333;
      font-size: 20px;
      line-height: 20px;
    }
    span{
      cursor: pointer;
    }
  }
}
</style>
          