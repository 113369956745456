<template>
  <div class="xxjj">
    <div class="page-herader">
      <span>{{ content.name }}</span>
    </div>
    <div v-if="!kycgShow" class="lmy-center">
      <div class="teachers_name">
        <div class="teachers_lb">
          <ul>
            <li
              v-for="(item, index) in content.contentData"
              :key="index"
              :title="item.title"
            >
              <!-- <a v-if="item.mapProperties.erOriginLink" :href="item.mapProperties.erOriginLink" target="_blank"
                >{{item.title}}</a> -->
              <span @click="detailsBTn(item)">{{ item.title }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div v-if="kycgShow" class="lmy-center ser_content">
      <div
        v-if="contentData.primersTitle"
        style="
          padding: 0 0 0.52083vw 0;
          font-size: 1.45833vw;
          color: #333333;
          font-weight: 500;
        "
      >
        {{ contentData.primersTitle }}
      </div>
      <div style="font-size: 1.77083vw; text-align: center">
        {{ contentData.title }}
      </div>
      <div
        v-if="contentData.subTitle"
        style="
          padding: 1.04167vw 1.04167vw 0.52083vw 1.04167vw;
          font-size: 1.45833vw;
          color: #333333;
          text-align: right;
          font-weight: 500;
        "
      >
        {{ contentData.subTitle }}
      </div>
      <div
        class="content_source"
        style="padding: 0.83333vw 0 0 3.64583vw; font-size: 0.83333vw; color: #6c6a6a"
      >
        <el-divider direction="vertical"></el-divider>
        <!-- <span v-if="contentData.mapProperties.wenzi">文字：{{ contentData.mapProperties.wenzi || '' }}</span> -->
        <!-- <span v-if="contentData.mapProperties.sheying">摄影：{{ contentData.mapProperties.sheying }}</span> -->
        <span v-if="contentData.author"
          >{{ $t("source_4") }}：{{ contentData.author }}</span
        >
        <span v-if="contentData.origin"
          >{{ $t("source_1") }}：{{ contentData.origin }}</span
        >
        <span v-if="contentData.publishTime">{{
          $moment(contentData.publishTime).format("YYYY-MM-DD")
        }}</span>
      </div>
      <div class="content ew_content_table" v-html="contentHtml"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      contentData: "",
      content: "",
      contentHtml: "",
      kycgShow: true,
    };
  },
  watch: {
    content(val) {
      if (val.uuid != this.$columnIdFile.kxyjkycg) {
        this.kycgShow = false;
        this.getHomeRotographListFun();
      } else {
        this.kycgShow = true;
        this.getHomeRotographListKYCGFun();
      }
    },
  },
  methods: {
    detailsBTn(val) {
      if (val?.mapProperties?.erOriginLink) {
        window.open(val.mapProperties.erOriginLink);
        return;
      }
      this.kycgShow = true;
      this.contentHtml = val.content;
      this.contentData = val;
    },
    async getHomeRotographListFun() {
      const params = {
        pageNumber: 0,
        pageSize: 100,
        columnIds: this.content.uuid,
      };
      const res = await this.API.basic.getHomeRotographList(params);
      this.content.contentData = res.data.content;
      this.$forceUpdate();
    },
    async getHomeRotographListKYCGFun() {
      const params = {
        pageNumber: 0,
        pageSize: 1,
        columnIds: this.content.uuid,
      };
      const res = await this.API.basic.getHomeRotographList(params);
      this.contentHtml = res.data.content[0].content;
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep a {
  color: #002bfd !important;
}
img {
  max-width: 100%;
}
.content_source {
  span {
    padding-right: 16px;
  }
}
.lmy-center {
  margin-top: 18px;
  margin-bottom: 36px;

  .teachers_name {
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
  }
  .teachers_lb {
    font-size: 20px;
    padding: 10px 0;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
  }
  ul li {
    width: 420px;
    margin-left: 20px;
    margin-right: 20px;
    border-bottom: solid 1px #e3e3e3;
    padding: 20px;
    a {
      color: #333;
      font-size: 20px;
      line-height: 20px;
    }
    span{
      cursor: pointer;
    }
  }
}
.ser_content {
  margin-top: 18px;
  margin-bottom: 36px;
  p {
    font-size: 24px;
    text-align: center;
  }
  img {
    max-width: 100%;
  }
  .content {
    ::v-deep a {
      color: #002bfd !important;
    }
    padding: 0 100px;
    font-size: 16px;
    #zoom {
      margin-top: 50px;
    }
  }
}
table.noBorderTable td,
table.noBorderTable th,
table.noBorderTable caption {
  border: 1px dashed #ddd !important;
}

table {
  margin-bottom: 10px;
  border-collapse: collapse;
  display: table;
}

td,
th {
  padding: 5px 10px;
  font-size: 0.1rem !important;
  border: 1px solid #ddd;
}

caption {
  border: 1px dashed #ddd;
  border-bottom: 0;
  padding: 3px;
  text-align: center;
}
tbody {
  font-size: 0.1rem !important;
}
th {
  border-top: 1px solid #bbb;
  background-color: #f7f7f7;
}

table tr.firstRow th {
  border-top-width: 2px;
}

.ue-table-interlace-color-single {
  background-color: #fcfcfc;
}

.ue-table-interlace-color-double {
  background-color: #f7faff;
}

td p {
  margin: 0;
  padding: 0;
}
</style>
