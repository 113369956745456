<template>
  <div class="xxjj">
    <div class="page-herader">
      <span>科研成果</span>
    </div>
    <div class="lmy-center">
        <div v-html="content">

        </div>
    </div>
  </div>
</template>
              
<script>
export default {
  data() {
    return {
      content: ""
    };
  },
  mounted() {
    this.getStory();
  },
  methods: {
    async getStory() {
      const res = await this.API.basic.getStory('WS6359e504e4b03007b5f024a4');
      this.content = res.data.content
    },
  }
};
</script>
              
<style lang="scss" scoped>
.lmy-center {
  margin-top: 18px;
  margin-bottom: 36px;
  padding: 20px 70px;
  ::v-deep table {
    max-width: 100%;
    background-color: transparent;
    empty-cells: show;
    border-collapse: collapse;
    border-spacing: 0;
    margin: auto;
  }
  .content {
    padding: 0 100px;
    font-size: 16px;
  }
}
</style>
              